.item {
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
  .itemTitle {
    height: 3.75rem;
    background: #f1f2f4;
    border-radius: 5px 5px 0px 0px;
    padding: 0rem 1.375rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &_text {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #29394d;
      display: flex;
      align-items: center;
    }

    &_icon {
      text-align: right;
    }
  }
  .search {
    background-color: #fff;
  }
  .activeTitle {
    background: #c7e8fe;

    .itemTitle_icon svg {
      transform: rotate(180deg);
      color: #18a0fb;
    }
  }

  .actions {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    position: relative;
  }

  .threeDots {
    margin-right: 0.5rem;
    transform: rotate(90deg);
    cursor: pointer;
    font-size: 14px;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &.active {
      background-color: #18a0fb;
      color: #fff;
      font-size: 12px;
    }
  }

  .itemContent {
    display: none;
    background: #ffffff;
    border: 1px solid #c7e8fe;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    padding: 1.188rem 1.375rem 1.438rem 1.375rem;
    p {
      font-weight: 400;
      font-size: 1rem;
      line-height: 140%;
      margin-bottom: 1.688rem;
    }
    .contentFooter {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: auto;
      .author {
        display: flex;
        align-items: center;
        margin-bottom: 1.313rem;
        .img {
          width: 58px;
          height: 34px;
          border-radius: 5px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          background-color: #faf9fa;
          margin-right: 0.813rem;
        }
      }
      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .question {
          width: 75%;
          display: flex;
          flex-direction: row;
          align-items: center;
          p {
            margin: 0;
            width: fit-content;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #6d7f92;
          }
          button {
            margin-left: 0.75rem;
            width: 75px;
            height: 34px;
            background: #ffffff;
            border: 1px solid #c7e8fe;
            box-sizing: border-box;
            border-radius: 5px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            color: #18a0fb;
            box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        button {
          padding: 0rem 1.125rem;
          width: 25%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #f1f2f4;
          border-radius: 5px;
          border: none;
          font-weight: 500;
          font-size: 14px;
          line-height: 140%;
          color: #6d7f92;
          cursor: pointer;
          span {
            display: block;
            width: max-content;
          }
          svg {
            margin-right: 5%;
          }
        }
      }
    }
  }
  .activeContent {
    display: initial;
  }
}

.item_edit {
  font-size: 12px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #b2bcc6;
    border-radius: 50%;
  }
}

.suggestExist {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #6d7f92;
  text-align: center;
  cursor: pointer;
}
.dragHandler {
  height: 100%;
  font-size: 2rem;
  color: #b6bfc8;
  cursor: move;
  display: flex;
  justify-content: center;
  margin-right: 1.438rem;
}

@media screen and (max-width: 1024px) {
  .item .itemContent .contentFooter {
    div .user {
      width: 24%;
    }
    .info {
      .question {
        width: 65%;
      }
      button {
        width: 35%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
}

@media screen and (max-width: 375px) {
  .item {
    margin-bottom: 4%;
    .itemTitle {
      padding: 4%;
      padding-right: 7%;
      h1 {
        font-size: 15px;
      }
    }
    .itemContent {
      padding: 0% 4%;
      p {
        padding-top: 2%;
        width: 97%;
      }
      .contentFooter {
        div .user {
          width: 51%;
        }
        .info {
          margin-top: 0%;
          .question {
            width: 75%;
            margin-top: 1%;
            margin-bottom: 2%;
          }
          button {
            width: fit-content;
            padding: 0% 4%;
            span {
              display: none;
            }
          }
        }
      }
    }
  }
}

// modal styling
.modal_close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.modal_header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 1rem;
  background: #f3faff;
  border-bottom: 1px solid rgba(199, 232, 254, 0.5);
  border-radius: 0px 0px 5px 5px;
  font-size: 20px;
  line-height: 23px;
  color: #2495e1;

  &_sub {
    font-size: 18px;
    margin-left: 10px;
  }
}
.modal_icon {
  margin-right: 1rem;
}
.modal_body {
  width: 100%;
  height: 100%;
  max-height: 39rem;
  overflow-y: scroll;
  padding: 20px 42px 42px 42px;
  border-bottom: 1px solid #f1f2f4;
  font-size: 18px;
  line-height: 21px;
  color: #29394d;
}

.status {
  font-size: 12px;
  line-height: 14px;
  background-color: #b2bcc6;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  margin: 0 10px;

  &.published {
    background-color: #06d9b1;
  }
}

.attachments {
  padding-bottom: 2rem;
  word-break: break-word;

  i {
    margin-right: 0.5rem;
  }
}
.pdfsList {
  display: flex;
  flex-wrap: wrap;
  li {
    margin-right: 4rem;
  }
}

.status_eye {
  color: #18a0fb;
  position: relative;
  cursor: pointer;
  margin-left: 10px;

  i {
    font-size: 18px;
  }

  &::before {
    display: none;
    content: " ";
    border-left: 2px solid #18a0fb;
    position: absolute;
    top: -5px;
    bottom: 0;
    left: 40%;
    transform: rotate(45deg);
  }

  &.hidden {
    opacity: 0.7;

    &::before {
      display: block;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin: 0.75rem 0 1.75rem;
  padding-bottom: 1.75rem;
  border-bottom: 1px solid #f1f2f4;
}
.tag {
  margin-right: 0.25rem;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #3c4e64;
  min-height: 26px;
  padding: 5px 14px;
  margin-bottom: 4px;
  background: rgba(109, 127, 146, 0.1);
  border: 1px solid #f3faff;
  box-sizing: border-box;
  border-radius: 2px;
}

.toolTipContainer {
  position: absolute;
  top: 50%;
  left: -270px;
  transform: translateY(-50%);
  z-index: 1;
}
.title_sharingDate {
  display: flex;
  flex-direction: column;
  .sharingDate {
    font-size: 0.75rem;
    color: #6d7f92;
    width: max-content;
  }
}
