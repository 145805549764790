.header {
  background: #f3faff;
  color: #3c4e64;

  border-radius: 0px 0px 10px 10px;
  margin-bottom: 23px;
  width: 100%;

  > div {
    display: flex;
    height: 96px;
    padding: 18px 0.625rem;
    max-width: 75rem;
    justify-content: space-between;

    margin-left: auto;
    margin-right: auto;
  }
  i {
    font-size: 2rem;
  }
}
.title {
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
}
.subtitle {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}
.left,
.right {
  display: flex;
}
.left {
  align-items: center;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    padding-left: 30px;
    border-left: 1px solid #fff;
    // box-shadow: -4px 0px 10px rgba(41, 57, 77, 0.1);
  }
}
.body {
  padding: 0 10rem 10rem;
}
