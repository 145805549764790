.container {
  padding: 2rem 3rem;
  margin: 2rem 3rem;
  background: #ffffff;
  border: 0.5px solid #f1f2f4;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
  border-radius: 5px;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.subtitle {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6d7f92;
  margin-bottom: 1rem;
}

.bubbles {
  position: fixed;
  top: 16rem;
  right: -134px;
  color: #29394d;
  font-weight: 500;
  font-size: 16px;
  z-index: 2;
  transition: 0.6s all ease-in-out;

  & > div {
    background: #fff;
    box-shadow: 0px 4px 5px rgba(60, 78, 100, 0.2);
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    transition: 0.5s all ease-in-out;
    cursor: pointer;
    position: relative;
    width: 205px;
    height: 62px;
    border-radius: 31px 0px 0px 31px;
    padding: 0 1rem;
    transform: translate(0);

    font-size: 16px;
    line-height: 19px;

    &.disabled {
      cursor: initial;
      color: #ccc;

      &:after {
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        content: "";
        border-radius: 31px 0px 0px 31px;
        background: rgba(41, 57, 77, 0.1);
      }
    }

    i {
      font-size: 1.5rem;
      z-index: 2;
      padding: 0 0.1rem 0 0.3rem;
    }

    span,
    div {
      text-transform: uppercase;
      text-align: center;
      opacity: 0;
    }

    &:not(.disabled):hover,
    &.active {
      transform: translate(-110px);
      background: #2495e1;
      box-shadow: 0px 4px 5px rgba(109, 127, 146, 0.2);
      color: #fff;
      width: 220px;

      span,
      div {
        opacity: 1;
      }
    }
  }
}

.itemsContainer {
  margin: 0 -7px;
  margin-top: 1rem;
}
.domainItem {
  padding: 0 7px;
  margin-bottom: 14px;
}
.itemCard {
  height: 4.875rem;
  border: 1px solid rgba(178, 188, 198, 0.5);
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
  border-radius: 5px;
  padding: 0.5rem 0.75rem;
  transition: all 0.3s ease;
  .content {
    padding: 0.5rem 0rem;
    display: flex;
    align-items: center;
    i {
      font-size: 32px;
    }
    span {
      padding-left: 0.5rem;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    i {
      font-size: 14px;
      cursor: pointer;
    }
    .delete {
      color: #fe3745;
      border-top: 1px solid rgba(109, 127, 146, 0.2);
      padding-top: 10px;
    }
  }
  &:hover {
    border: 1px solid #18a0fb;
    .actions {
      opacity: 1;
    }
  }
}
