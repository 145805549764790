.content {
  margin-top: 2.188rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .tabs {
    display: flex;
    align-items: center;
    li {
      width: 6.25rem;
      border-bottom: 2.97px solid #b2bcc6;
      text-align: center;
      font-weight: 500;
      font-size: 0.875rem;
      color: #6d7f92;
      padding-bottom: 0.5rem;
      margin-right: 0.625rem;
      cursor: pointer;
    }
    .selected {
      color: #18a0fb;
      border-color: #18a0fb;
    }
  }
}

.header_margin {
  margin-bottom: 1.5rem;
}

.search {
  width: 18.75rem;
  height: 2rem;
  border: 1px solid #d8dde2;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  input {
    width: 86%;
    border: none;
    outline: none;
    margin-left: 0.875rem;
  }
}

.embed_input {
  width: 100%;
  input {
    width: 95%;
  }
}
.inputCont {
  height: 4rem;
  margin-bottom: 1rem;
  label {
    color: #d50000;
    margin-left: 2rem;
    font-size: 12px;
    margin-top: 4px;
  }
}
.categoriesContainer {
  position: relative;
  margin: 1.5rem auto;
  .categories {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      height: 32px;
      padding: 0 18px;
      background-color: #f1f2f4;
      border-radius: 2rem;
      color: #6d7f92;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.5s ease-in-out;
      white-space: nowrap;

      &:hover {
        background: #e1e4e8;
      }
    }
    .activeCategory {
      background: #6d7f92;
      color: #ffffff;
      &:hover {
        background: #6d7f92;
        color: #ffffff;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
  .scrollButton {
    position: absolute;
    top: 0%;
    height: 2rem;
    width: 9.375rem;
    background: linear-gradient(
      270deg,
      #fafbfb 0%,
      rgba(250, 251, 251, 0.8) 64.58%,
      rgba(250, 251, 251, 0) 100%
    );
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
    }
  }
  .scrollButtonForward {
    right: 0%;
  }
  .scrollButtonBack {
    left: 0%;
    background: linear-gradient(
      -270deg,
      #fafbfb 0%,
      rgba(250, 251, 251, 0.8) 64.58%,
      rgba(250, 251, 251, 0) 100%
    );
    justify-content: flex-start;
    button {
      transform: rotate(180deg);
    }
  }
}
.listContainer {
  min-height: 15rem;
  max-height: 25rem;
  overflow: scroll;
}
.media_list {
  margin: 0 -10px;
}
.cardCont {
  padding: 0 10px;
  padding-bottom: 20px;
  z-index: 9999;
}
.section_title {
  margin: 1.5rem 0rem;
  font-weight: 500;
  font-size: 1.125rem;
}
.selected_media_container {
  border-bottom: 1px solid #d8dde2;
}
.uploadingMedia {
  position: relative;
  height: 7.5rem;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f2f4;

  .uploadingMediaOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.formControls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(178, 188, 198, 0.5);

  .okBtn {
    min-width: 170px;
    justify-content: center;
  }

  .actions {
    display: flex;

    span {
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #b2bcc6;
      border-radius: 5px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;

      &:hover {
        background-color: rgba(109, 127, 146, 0.1);
      }
    }
  }
}
