.sidebarAuthor {
  cursor: move;
  background-color: #f1f2f4;
  border-radius: 3px;
  padding: 10px;
  // margin-bottom: 20px;
  margin: 0 auto 20px;
  width: 207px;
  position: relative;
}
.authorRemove {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.sidebarAvatar {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  box-sizing: border-box;
  background-color: #f1f2f4;
  background-size: cover;
  background-position: center;
  margin: 10px auto 20px;
  filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
}
.avatarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eee;
  opacity: 0.6;
  border-radius: 50%;
}
.iconEye {
  width: 28px;
  height: 28px;
  color: #fff;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #18a0fb;

  &.disabledAvatar {
    background-color: #aaa;
    &:after {
      content: " ";
      border-left: 2px solid #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 47%;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &.disabledAvatarDeleted {
    background-color: #ff5733;
    &:after {
      content: " ";
      border-left: 2px solid #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 47%;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.sidebarUsername {
  input[type="text"] {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
}

.editableInput {
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    margin: 5px auto 15px;
    background: #ced4db;
  }
  input[type="text"] {
    text-align: center;
    margin: 0;
    border: none;
    background-color: transparent;
    box-shadow: none;
    width: 100%;
  }
}

.sidebarPost {
  input[type="text"] {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #6d7f92;
  }
}
