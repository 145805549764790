.status_trigger {
  position: fixed;
  top: 70px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #c7e8fe;
  background: #29394d;
  border-radius: 8px;
  width: 56px;
  height: 60px;
  cursor: pointer;

  &::before {
    content: " ";
    border-left: 2px solid #c7e8fe;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    margin: 8px 0;
    transform: rotate(45deg);
  }

  &_isAdmin {
    top: 125px;
  }

  &.active {
    color: #18a0fb;

    &::before {
      display: none;
    }
  }
}

.trigger {
  position: fixed;
  top: 140px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #c7e8fe;
  background: #29394d;
  border-radius: 8px;
  width: 56px;
  height: 60px;
  cursor: pointer;
}

.question_trigger {
  position: fixed;
  top: 210px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #c7e8fe;
  background: #29394d;
  border-radius: 8px;
  width: 56px;
  height: 60px;
  cursor: pointer;
}
