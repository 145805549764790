*,
h1,
h2,
h3,
h4,
ul,
p {
  margin: 0;
  padding: 0;
}

.pagesPage {
  background-color: #fafbfb;
  color: #29394d;
}
.backNav {
  height: 60px;
  padding-left: 5.7%;
  background: #f3faff;
  border-bottom: 1px solid #c7e8fe;
  display: flex;
  align-items: center;
  button {
    height: 2.25rem;
    padding: 0px 1.188rem;
    background: #f8f9fa;
    border: 1px solid #b2bcc6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    margin-right: 1.188rem;
    svg {
      margin-right: 0.688rem;
    }
  }
}

.pageContent {
  padding: 2.063rem 5.9%;
}
.body {
  margin: 0 -7px;
}
.bannerDiv {
  padding: 0 7px;
}
.leftBanner {
  background-color: #f1f2f4;
  padding: 1.125rem 1.25rem;
  margin-bottom: 1.5rem;
  border-radius: 5px;
}
.listContent {
  padding: 0 7px;
}
.cardsList {
  margin: 0 -7px;
}
.domain {
  margin-bottom: 1.813rem;
  position: relative;
  span {
    background-color: #18a0fb;
    color: #fff;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2rem;
    padding: 0 1.188rem 0 1.563rem;
    font-size: 14px;
    text-transform: uppercase;
    border: 3px solid #d8dde2;
    cursor: pointer;
  }
  ul {
    position: absolute;
    left: 0%;
    top: 44px;
    width: 100%;
    display: none;
    background: #18a0fb;
    color: #fff;
    z-index: 1;
    li {
      padding: 8px;
      cursor: pointer;
      &:hover {
        background: #178ede;
      }
    }
  }
  .activeList {
    display: initial;
  }
}
.activeItem {
  color: #18a0fb !important;
}

.unset {
  color: #29394d;
  cursor: pointer;
}

.themesLinks {
  margin-top: -10px;
  li {
    padding: 10px 0px;
    border-bottom: 1px solid #d8dde2;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    text-transform: uppercase;
    svg {
      margin: auto;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .pagesLinks {
    width: 100%;
    margin-left: 5% !important;
    margin: 21px 0px 11px 0px;
    li {
      padding: 3px 0px;
      border-bottom: none;
      font-size: 0.875rem;
      &:last-child {
        padding-bottom: 0px;
      }
      &:first-child {
        padding-top: 0px;
      }
    }
  }
  .rubricsLinks {
    width: 100%;
    margin-left: 5%;
    margin: 1rem 0rem;
    .rubricName {
      padding: 6px 0px;
      border-bottom: 1px solid #d8dde2;
      font-size: 0.875rem;
      text-transform: capitalize;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #29394d;
      cursor: pointer;

      &_text {
        margin-right: 5px;
      }

      &:last-child {
        padding-bottom: 0px;
        border-bottom: none;
      }
      &:first-child {
        padding-top: 0px;
      }
    }
  }
}

.draftRubric {
  opacity: 0.5;

  .rubricName_text {
    text-decoration: line-through;
  }
}

.rubricCardCont {
  padding: 0 7px;
  margin-bottom: 14px;
}

.eye_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2%;
}

.status_eye {
  position: relative;
  cursor: pointer;

  i {
    font-size: 14px;
  }

  &::before {
    display: none;
    content: " ";
    border-left: 2px solid;
    position: absolute;
    top: -5px;
    bottom: 0;
    left: 40%;
    transform: rotate(45deg);
  }

  &.hidden {
    opacity: 0.7;

    &::before {
      display: block;
    }
  }
}

.questionsList {
  margin: 0 -5px;
}
.cardCont,
.qCardCont {
  padding: 0 5px;
}
.cardCont {
  margin-bottom: 0.625rem;
}
