// *,
// h1,
// h2,
// h3,
// h4,
// ul,
// p {
//   margin: 0;
//   padding: 0;
// }
button {
  cursor: pointer;
}
.homePage {
  padding: 2rem 4% 3rem;
  // background-color: #fafbfb;
  color: #29394d;
}
.header {
  padding: 0 2.45% 2.25rem;
}
.needHelp {
  margin-top: 1rem;
  text-align: center;
  h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  span {
    font-size: 1rem;
    color: #6d7f92;
  }
}
.search {
  padding: 1.5rem 0 1rem;
}
.domaine {
  padding-right: 1.25rem;
  margin-bottom: 0.5rem;
  position: relative;
  span {
    background-color: #18a0fb;
    color: #fff;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2rem;
    padding: 0 12px 0 24px;
    font-size: 14px;
    text-transform: uppercase;
    border: 3px solid #d8dde2;
    cursor: pointer;
  }
  ul {
    position: absolute;
    left: 0%;
    top: 44px;
    width: 100%;
    display: none;
    background: #18a0fb;
    color: #fff;
    z-index: 1;
    li {
      padding: 8px;
      cursor: pointer;
      &:hover {
        background: #178ede;
      }
    }
  }
  .activeList {
    display: initial;
  }
}
.inputSearch {
  padding-right: 18px;
  margin-bottom: 0.5rem;
  .inputContent {
    background-color: white;
    border: 1px solid #d8dde2;
    border-radius: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    input {
      border: none;
      outline: none;
      width: 95%;
      height: 44px;
    }
  }
}
.categoriesContainer {
  position: relative;

  .categories {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      height: 32px;
      padding: 0 18px;
      background-color: #f1f2f4;
      border-radius: 2rem;
      color: #6d7f92;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.5s ease-in-out;
      white-space: nowrap;

      &:hover {
        background: #e1e4e8;
      }
    }
    .activeCategory {
      background: #6d7f92;
      color: #ffffff;
      &:hover {
        background: #6d7f92;
        color: #ffffff;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
  .scrollButton {
    position: absolute;
    top: 0%;
    height: 2rem;
    width: 9.375rem;
    background: linear-gradient(
      270deg,
      #fafbfb 0%,
      rgba(250, 251, 251, 0.8) 64.58%,
      rgba(250, 251, 251, 0) 100%
    );
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
    }
  }
  .scrollButtonForward {
    right: 0%;
  }
  .scrollButtonBack {
    left: 0%;
    background: linear-gradient(
      -270deg,
      #fafbfb 0%,
      rgba(250, 251, 251, 0.8) 64.58%,
      rgba(250, 251, 251, 0) 100%
    );
    justify-content: flex-start;
    button {
      transform: rotate(180deg);
    }
  }
}

.body {
  margin: 0 -7px;
}
.guide {
  padding: 0 7px;
  .followGuide {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 1.438rem 1.188rem 3rem 1.25rem;
    background-image: url(./assets/Detective.jpeg);
    background-size: cover;
    border: 1px solid #f1f2f4;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 1.25rem;
    span,
    h3,
    button {
      position: relative;
      text-transform: uppercase;
    }
    span {
      display: block;
      color: #c7e8fe;
      font-size: 1.25rem;
      margin-bottom: 7px;
    }
    h3 {
      color: #ffffff;
      font-size: 1.5rem;
      line-height: 1.75rem;
      margin-bottom: 22px;
      font-weight: 700;
    }
    button {
      padding-left: 1.5rem;
      padding-right: 1rem;
      height: 2.75rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      border: 1px solid #d8dde2;
      border-radius: 5px;
      background-color: #ffffff;
      svg {
        margin-left: 1.25rem;
      }
    }
  }
  .followGuide::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #29394dcc;
    border-radius: 5px;
  }

  .questionsList {
    padding: 1.313rem 1rem 1.313rem 1.3rem;
    background: #f3faff;
    border: 1px solid #c7e8fe;
    border-radius: 5px;

    span {
      display: block;
      color: #18a0fb;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      margin-bottom: 7px;
    }
    h3 {
      margin-bottom: 23px;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      text-transform: uppercase;
    }
    ul {
      li {
        font-weight: 400;
        font-size: 14px;
        padding: 8px 0;
        border-bottom: 1px solid #d8dde2;

        text-transform: capitalize;
        line-height: 1.25;

        &:last-child {
          border: none;
        }
      }
    }
  }
}
.themes {
  padding: 0 7px;
}
.cards {
  margin: 0 -7px;
}
.cardCont {
  padding: 0 7px;
}
.card {
  position: relative;
  height: 450px;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  margin-bottom: 14px;
  padding: 30px 20px;

  .childrenList {
    padding: 0.5rem 0 2.5rem 0;
    min-height: 233px;
    max-height: 260px;
    overflow-y: auto;
    li {
      padding: 8px 0;
      border-bottom: 1px solid #d8dde2;
      display: flex;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      &:last-child {
        border: none;
      }
      &:hover {
        p,
        svg {
          color: #18a0fb;
        }
      }
    }
  }
  .blueArrow {
    margin-left: auto;
    margin-right: 5%;
    width: fit-content;
    opacity: 0%;
    cursor: pointer;
  }
  &:hover {
    .blueArrow {
      opacity: 100%;
    }
  }
}
.icon {
  text-align: center;
  margin-bottom: 1rem;
  i {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 1rem;
    height: 4.25rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    cursor: pointer;
    em {
      font-style: normal;
      color: #18a0fb;
    }
  }
}
.nbPages {
  margin-top: 1.5rem;
  text-align: center;
  border-top: 1px solid #d8dde2;
  span {
    position: relative;
    background-color: #fff;
    padding: 0 26px;
    top: -13px;
  }
}

.answer {
  padding: 1rem 0;
  em {
    font-style: normal;
    color: #18a0fb;
  }
}

.filterHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.45% 1.5rem;
  h4 {
    font-size: 22px;
    font-weight: 400;
  }

  span {
    cursor: pointer;
    color: #18a0fb;
  }
}

.rubCardCont {
  padding: 0 7px;
  margin-bottom: 14px;
}

@media screen and (max-width: 1024px) {
  .guide .followGuide button {
    padding-left: 0.5rem;
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 375px) {
  .categoriesContainer .scrollButton {
    width: 5rem;
  }
}

.status_eye {
  color: #18a0fb;
  position: relative;
  cursor: pointer;

  &_absolute {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  i {
    font-size: 18px;
  }

  &::before {
    display: none;
    content: " ";
    border-left: 2px solid #18a0fb;
    position: absolute;
    top: -5px;
    bottom: 0;
    left: 40%;
    transform: rotate(45deg);
  }

  &.hidden {
    opacity: 0.7;

    &::before {
      display: block;
    }
  }
}
