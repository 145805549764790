.media {
  position: relative;
  height: 7.5rem;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  position: relative;
  border-radius: 5px;
}
.doc,
.mediaContent {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  img {
    width: 4.125rem;
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0.75rem;
  background: linear-gradient(
    180deg,
    rgba(41, 57, 77, 0) 0%,
    rgba(41, 57, 77, 0.7) 55.73%
  );

  border-radius: 5px;
  color: #fff;
  cursor: pointer;

  h3 {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25;
    max-height: 3.25rem;
    overflow: hidden;
    color: #fff;
  }
  &:hover {
    .blue_overlay {
      opacity: 1;
    }
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    &.docImg {
      height: 33px;
      width: 30px;
      left: 10%;
      top: 10%;
      transform: translate(-20%, -20%);
    }
  }
}

.blue_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(24, 160, 251, 0.5);
  color: #fff;
  opacity: 0;
  padding: 0.688rem;
  cursor: default;
  &.selected {
    opacity: 1;
    &:hover {
      .icon {
        .other {
          display: none;
        }
        .check {
          display: initial;
        }
      }
    }
  }
  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .icon {
      height: 2.5rem;
      width: 2.5rem;
      background: #ffffff;
      border: 1px solid #18a0fb;
      border-radius: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #18a0fb;
      font-weight: 500;
      font-size: 18px;
      .check {
        display: none;
      }
    }
  }
}
.preview {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 500;
  font-size: 0.625rem;
  text-transform: uppercase;
  text-align: end;
  cursor: pointer;
}
.modal_close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}
.modal_body {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit {
  position: absolute;
  top: 0.75rem;
  right: 3rem;
  display: flex;
  align-items: center;
  .status {
    font-size: 12px;
    line-height: 14px;
    background-color: #b2bcc6;
    color: #fff;
    padding: 4px;
    border-radius: 4px;
    margin: 0 10px;

    &.published {
      background-color: #06d9b1;
    }
  }
  .item_edit {
    font-size: 12px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b2bcc6;
    border-radius: 50%;
    cursor: pointer;
  }
}

.mediaContainer {
  height: 17.125rem;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  border-radius: 5px;
  // margin-bottom: 0.625rem;
  position: relative;
}
.docContainer {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: flex-start;
  padding-top: 1rem;
}

.dragHandler {
  height: 100%;
  width: 3.125rem;
  font-size: 2rem;
  color: #b6bfc8;
  cursor: move;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}
.docPreview {
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 11.25rem;
  height: 14.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.docInfo {
  width: calc(100% - 11.25rem);
  padding: 0.75rem 1rem;
  .title {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 1.375rem;
  }
  span {
    display: block;
    font-size: 0.875rem;
    margin-bottom: 0.3rem;
  }
  .download {
    width: 10.375rem;
    height: 2.25rem;
    background-color: #f1f2f4;
    color: #6d7f92;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 0.875rem;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 1.5rem;
    svg {
      margin-right: 0.5rem;
    }
  }
}

.docContainerPPT {
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  .docPreview {
    height: 11.25rem;
    width: 18rem;
  }
  .docInfo {
    width: 100%;
    padding: 0.5rem;

    .title {
      margin-bottom: 0.5rem;
    }
    .meta {
      padding: 0.5rem 0rem;
      span {
        margin: 0;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      .download {
        margin: 0;
      }
    }
  }
}
.youtubeContent {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  iframe {
    width: 100%;
    height: 100%;
  }
}

.threeDots {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  margin-right: 0.5rem;
  transform: rotate(90deg);
  cursor: pointer;
  font-size: 14px;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f1f2f4;
  &.active {
    background-color: #18a0fb;
    color: #fff;
    font-size: 12px;
  }
}

.sharingDate {
  font-size: 0.75rem;
  color: #6d7f92;
}

.toolTipContainer {
  position: absolute;
  top: 12%;
  right: 52px;
  transform: translateY(-50%);
  z-index: 1;
  &_youtube {
    top: 6.5%;
  }
}
