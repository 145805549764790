*,
h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}

.authorsPage {
  background-color: #fafbfb;
  color: #29394d;
}
.pageContent {
  padding: 2rem 5%;
}
.backNav {
  height: 60px;
  padding-left: 5.7%;
  background: #f3faff;
  border-bottom: 1px solid #c7e8fe;
  display: flex;
  align-items: center;

  button {
    height: 2.25rem;
    padding: 0px 1.188rem;
    background: #f8f9fa;
    border: 1px solid #b2bcc6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    margin-right: 1.188rem;
    svg {
      margin-right: 0.688rem;
    }
  }
  span {
    color: #18a0fb;
    font-size: 1rem;
    font-weight: 500;
  }
}

.pageHeader {
  margin-bottom: 3.688rem;
  .titles {
    h1 {
      font-weight: 500;
      font-size: 2rem;
      line-height: 38px;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }
    h2 {
      font-weight: 400;
      font-size: 1rem;
      line-height: 19px;
      color: #6d7f92;
    }
  }
  .filters {
    display: flex;
    align-items: center;
    .search {
      width: 340px;
      height: 32px;
      background: #ffffff;
      border: 1px solid #d8dde2;
      box-sizing: border-box;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0% 3%;
      margin-left: 0.813rem;
      input {
        width: 92%;
        border: none;
        outline: none;
      }
      svg {
        color: #b7bdc3;
      }
    }
  }
}
.authorsList {
  margin: 0 -7px;
}
.cardCont {
  padding: 0 7px;
  margin-bottom: 40px;
}
.author {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  .avatar {
    margin-bottom: -60px;
    z-index: 9;
    .img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border: 5px solid #f1f2f4;
      box-sizing: border-box;
    }
  }
  .info {
    padding-top: 70px;
    width: 309px;
    height: 174px;
    background: #ffffff;
    border: 1px solid #f1f2f4;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.3s ease-in-out;
    &:hover {
      filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
    }
    h2 {
      font-weight: 500;
      font-size: 1rem;
      line-height: 19px;
      text-align: center;
      margin-bottom: 4px;
    }
    h4 {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #6d7f92;
      margin-bottom: 2rem;
    }
  }
}
.nbPages {
  margin-bottom: 2rem;
  text-align: center;
  border-top: 1px solid #d8dde2;
  width: 90%;
  span {
    position: relative;
    background-color: #fff;
    color: #29394d;
    padding: 0 23px;
    top: -13px;
  }
}
.authorBanner {
  background: #f1f2f4;
  border: 1px solid #f1f2f4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 1.188rem 1.25rem;
  height: fit-content;
  margin-bottom: 2%;
  text-align: center;
  .avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.813rem;
    .main {
      height: 120px;
      width: 120px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border: 5px solid #f1f2f4;
      box-sizing: border-box;
      z-index: 98;
    }
    .logo {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background-color: #ffffff;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      margin-top: -36px;
      margin-right: -50px;
      z-index: 99;
      img {
        width: 30.8px;
        height: 22px;
      }
    }
  }
  h1 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    margin-bottom: 4px;
  }
  h2 {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 14px;
    color: #6d7f92;
    margin-bottom: 1.375rem;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #6d7f92;
    text-align: left;
  }
}
.solutionsList {
  padding: 0% 1.5%;
  h3 {
    margin-bottom: 1.375rem;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 28px;
  }
}
