.container {
  padding: 2rem 3rem;
  margin: 2rem 3rem;
  background: #ffffff;
  border: 0.5px solid #f1f2f4;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
  border-radius: 5px;
}
.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.subtitle {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #6d7f92;
}
.fetchItem {
  margin-top: 1.25rem;
}
.item {
  background: #ffffff;
  border: 1px solid rgba(178, 188, 198, 0.5);
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
  border-radius: 5px;
  margin-top: 1.25rem;

  &:hover {
    border: 1px solid #18a0fb;

    .item_actions {
      opacity: 1;
      top: 15px;
      background-color: #fff;
    }
  }

  &.active {
    border: 1px solid #18a0fb;

    .item_actions {
      opacity: 1;
      top: 15px;
    }

    .actions_show {
      transform: rotate(180deg);
      color: #18a0fb;
    }
  }

  &.selected {
    border: 1px solid #18a0fb;

    .item_actions {
      opacity: 1;
      top: 15px;
    }
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    padding: 1.25rem;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    border-bottom: 1px solid #f1f2f4;
    position: relative;

    &_left {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }
    }
  }

  .default_tag {
    color: #6d7f92;
    padding: 4px 8px;
    margin-left: 10px;
    border: 1px solid #dce1e5;
    border-radius: 5px;
  }

  &_actions {
    position: absolute;
    right: 1rem;
    top: -5px;
    opacity: 0;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
  }

  &_details {
    padding: 1.25rem 0;
  }

  &_replace {
    position: relative;
  }
}
.actions {
  &_delete {
    background: #fff;
    cursor: pointer;
    margin: 0 10px;
    padding: 0 10px;
    border-left: 1px solid rgba(109, 127, 146, 0.2);
    border-right: 1px solid rgba(109, 127, 146, 0.2);
    i {
      font-size: 14px;
      color: #fe3745;
    }
  }

  &_replace {
    margin-left: 10px;
    padding-left: 10px;
    cursor: pointer;
    border-left: 1px solid rgba(109, 127, 146, 0.2);
  }

  &_edit {
    cursor: pointer;
  }

  &_show {
    cursor: pointer;
    transition: all 0.3s ease;
  }

  &_page {
    &_delete {
      cursor: pointer;
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid rgba(109, 127, 146, 0.2);
      i {
        font-size: 14px;
        color: #fe3745;
      }
    }

    &_show {
      cursor: pointer;
      transition: all 0.3s ease;
    }
  }
}
.addPage_label {
  font-size: 14px;
  line-height: 16px;
  padding: 0 1.25rem;
  color: #6d7f92;
}
.addPage {
  display: flex;
  align-items: center;
  padding: 0 1.25rem;

  &_header {
    position: relative;
    display: flex;
    align-items: center;
    height: 44px;
    margin: 1.25rem 1rem 0;
    cursor: pointer;
    color: #18a0fb;

    > i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      margin-right: 0.5rem;
      border: 0.5px solid #c7e8fe;
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 3px;
    }

    span {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &_form {
    position: absolute;
    top: 46px;
    left: 0;
    z-index: 110;
    width: 100%;
    background-color: #f1f2f4;
    border-radius: 4px;
    border: 1px solid #2495e1;
    cursor: default;
    padding: 0.8rem;
    box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
  }
}
.itemPage {
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    // height: 44px;
    padding: 0.8rem;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    position: relative;

    margin: 1.25rem 1rem 0;
    border: 1px solid #dce1e5;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
    border-radius: 5px;
    background-color: #f8f9fa;

    &:hover {
      border: 1px solid #18a0fb;

      .itemPage_actions {
        opacity: 1;
        top: 15px;
      }
    }

    &.active {
      border: 1px solid #18a0fb;
      border-bottom: none;
      border-radius: 5px 5px 0 0;

      .itemPage_actions {
        opacity: 1;
        top: 15px;
      }

      .actions_page_show {
        transform: rotate(180deg);
        color: #18a0fb;
      }
    }
  }

  &_actions {
    position: absolute;
    right: 1rem;
    top: -5px;
    opacity: 0;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
  }

  &_form {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 110;
    width: 100%;
    background-color: #f1f2f4;
    border-radius: 0px 0px 4px 4px;
    border-bottom: 1px solid #2495e1;
    border-right: 1px solid #2495e1;
    border-left: 1px solid #2495e1;
    cursor: default;
    padding: 0.8rem;
    box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
  }
}
.themeCover {
  position: relative;
  height: 180px;
  width: 100%;
  color: #6d7f92;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  background-color: #f8f9fa;
  text-align: center;
  cursor: pointer;
  padding: 0 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px dashed #b2bcc6;
  border-radius: 5px;
}
.changeImg {
  margin-bottom: 1rem;
}

.selectIconArea {
  background-color: #f1f2f4;
  border-radius: 4px;
  padding: 0.5rem;
  margin: 1rem 0;
  cursor: pointer;
}

.selectIcon {
  font-weight: 500;
  border: 1px dashed #ccc;
  color: #6d7f92;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 8.75rem;
  height: 6.5rem;
  text-align: center;
  font-size: 0.75rem;
  i {
    font-size: 3rem;
    color: #18a0fb;
    opacity: 0.7;
  }
  p {
    margin: 0;
    font-size: 0.825rem;
    margin-top: 0.5rem;
    font-weight: 600;
    color: #18a0fb;
    text-transform: capitalize;
  }
  &.icon-selected {
    border: none;
    span {
      display: none;
    }
    &:hover {
      p {
        display: none;
      }
      border: 2px dashed #ccc;
      span {
        display: block;
      }
    }
  }
}

.formRow {
  margin-bottom: 1rem;
}
.formLabelFlex {
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }
}

.formInput {
  display: block;
  width: 100%;
  padding: 2px 10px;
  height: 38px !important;
  border: 1px solid #ced4db;
  border-radius: 5px;
}

.formSwitch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0;
}
.bubbles {
  position: fixed;
  top: 16rem;
  right: -134px;
  color: #29394d;
  font-weight: 500;
  font-size: 16px;
  z-index: 2;
  transition: 0.6s all ease-in-out;

  & > div {
    background: #fff;
    box-shadow: 0px 4px 5px rgba(60, 78, 100, 0.2);
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    transition: 0.5s all ease-in-out;
    cursor: pointer;
    position: relative;
    width: 205px;
    height: 62px;
    border-radius: 31px 0px 0px 31px;
    padding: 0 1rem;
    transform: translate(0);

    font-size: 16px;
    line-height: 19px;

    &.disabled {
      cursor: initial;
      color: #ccc;

      &:after {
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        content: "";
        border-radius: 31px 0px 0px 31px;
        background: rgba(41, 57, 77, 0.1);
      }
    }

    i {
      font-size: 1.5rem;
      z-index: 2;
      padding: 0 0.1rem 0 0.3rem;
    }

    span,
    div {
      text-transform: uppercase;
      text-align: center;
      opacity: 0;
    }

    &:not(.disabled):hover,
    &.active {
      transform: translate(-110px);
      background: #2495e1;
      box-shadow: 0px 4px 5px rgba(109, 127, 146, 0.2);
      color: #fff;
      width: 220px;

      span,
      div {
        opacity: 1;
      }
    }
  }
}
