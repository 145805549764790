*,
h1,
h2,
h3,
h4,
ul,
p {
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
.privacyPage {
  color: #29394d;
}
.backNav {
  height: 60px;
  padding-left: 5.7%;
  background: #f3faff;
  border-bottom: 1px solid #c7e8fe;
  display: flex;
  align-items: center;
  button {
    height: 2.25rem;
    padding: 0px 1.188rem;
    background: #f8f9fa;
    border: 1px solid #b2bcc6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    margin-right: 1.188rem;
    svg {
      margin-right: 0.688rem;
    }
  }
  span {
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: #18a0fb;
  }
}
.pageContent {
  padding: 2.25rem 5.7%;
}

.left {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  h1 {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

// .sticky {
//   position: -webkit-sticky; // required for Safari
//   position: sticky;
//   top: 0;
// }

.item {
  margin-bottom: 1.25rem;
  position: relative;
  .rubric {
    padding: 1.063rem 0rem;
    // height: 4.625rem;
    background: #ffffff;
    border: 1px solid #dce1e5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    .chapterTitle {
      font-weight: 500;
      font-size: 0.875rem;
      width: 90%;
      text-transform: capitalize;
      margin-left: 1rem;
      color: #29394d;
    }
    &_active {
      border: 1px solid #18a0fb;
    }
  }
}

.dragHandler {
  margin-left: 0.625rem;

  i {
    font-size: 2rem;
    color: #b7bfc8 !important;
    cursor: move;
  }
}

.rubricContent {
  margin-left: 1.125rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .title {
    font-weight: 500;
    font-size: 0.875rem;
    width: 90%;
    text-transform: capitalize;
  }
  .chapters {
    margin-left: 1rem;
    margin-top: 0.5rem;
    li {
      a {
        color: #29394d;
        &:hover {
          color: #18a0fb;
        }
      }
      margin-bottom: 0.3rem;
    }
  }
}

.details {
  display: flex;
  align-items: center;
  span {
    font-size: 0.75rem;
    color: #6d7f92;
  }
  .dot {
    height: 0.25rem;
    width: 0.25rem;
    margin: 0rem 0.5rem;
    background-color: #d8dde2;
    border-radius: 100%;
  }
  .languages {
    display: flex;
    align-items: center;
  }
}
.flag {
  width: 1.063rem;
  height: 0.75rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 0.625rem;
}
.arrow {
  margin-left: auto;
  margin-right: 1.188rem;
}
.isVisible {
  margin-top: 0.563rem;
  margin-left: 0.313rem;
  display: flex;
  align-items: center;
  .checkbox {
    cursor: pointer;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 100%;
    background: #f8f9fa;
    border: 2px solid #b2bcc6;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.75rem;
    &_active {
      background: #18a0fb;
      border: 2px solid #18a0fb;
    }
  }
  label {
    cursor: pointer;
    font-size: 0.75rem;
  }
}
.addRubric {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  button {
    height: 2.75rem;
    width: 2.75rem;
    background: #ffffff;
    border: 1px solid #c7e8fe;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    margin-right: 0.813rem;
  }
  label {
    font-weight: 500;
    font-size: 0.875rem;
    color: #18a0fb;
  }
}

.right {
  padding-left: 1.563rem;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  .actions {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    .edit {
      cursor: pointer;
    }
    .delete {
      cursor: pointer;
      border-left: 1px solid #d9dfe4;
      padding-left: 0.688rem;
      margin-left: 0.688rem;
    }
  }
}
.versions {
  display: flex;
  align-items: center;
  .active_label {
    color: #18a0fb;
  }
  li {
    font-weight: 500;
    cursor: pointer;
    font-size: 0.875rem;
    color: #6d7f92;
    padding: 0rem 1.063rem;
    border-left: 1px solid #d9dfe4;
    &:first-child {
      border-left: none;
    }
  }
}

.rubric_display {
  position: relative;
  padding: 1.688rem;
  padding-bottom: 2.5rem;
  width: 100%;
  background: #ffffff;
  border: 1px solid #18a0fb;
  border-radius: 5px;
  .title {
    font-weight: 500;
    font-size: 1rem;
  }
  .description {
    margin-top: 0.938rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.languageTabs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  ul {
    display: flex;
    align-items: center;
    li {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 1.25rem;
      .flag {
        margin-right: 0rem;
        margin-bottom: 0.625rem;
      }
      .border {
        height: 3px;
        width: 2.5rem;
        background-color: #b2bcc6;
        border-radius: 2px;
      }
    }
  }
}

.isActive {
  display: flex;
  align-items: center;
  .label {
    display: block;
    margin-right: 1.188rem;
    font-weight: 500;
    font-size: 0.875rem;
    color: #6d7f92;
  }
  .active_label {
    color: #18a0fb;
  }
}
.rubric_form {
  width: 100%;
  background: #ffffff;
  border: 1px solid #18a0fb;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
}
.formBody {
  padding: 2rem 1.75rem;
  overflow: scroll;
  padding-bottom: 0px;
  height: 620px;
}
.statusRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.statusSelect {
  width: 45%;
  label {
    display: block;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
}

.formRow {
  margin-bottom: 1.5rem;
}

.formInput {
  display: block;
  width: 100%;
  padding: 2px 10px;
  height: 38px !important;
  border: 1px solid #ced4db;
  border-radius: 5px;
  color: #29394d;
  background-color: #f8f9fa;
  border: 1px solid #b2bcc6;
}
.langTab {
  label {
    display: block;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
}
.formFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.063rem 1.75rem;
  box-shadow: 0px -4px 10px rgba(41, 57, 77, 0.1);
}
.saving {
  height: 100%;
  width: 100%;
  background-color: #ffffff95;
  position: absolute;
  top: 0;
  left: 0;
}

.saveOrder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fakeTop {
  height: 2.5rem;
}

.hideOnIpad {
  display: block;
}

.showOnIpad {
  display: none;
}

.headerTab {
  display: flex;
  justify-content: center;
  margin-top: -27px;

  &__button {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 206px;
    height: 54px;
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #18a0fb;
    outline: none;
    text-transform: uppercase;
    transition: box-shadow 0.2s, transform 0.2s;
    border: none;
    &:hover {
      box-shadow: 2px 2px 11px rgba(41, 57, 77, 0.05);
      transform: scale(0.99);
    }

    &_active {
      background: linear-gradient(180deg, #2495e1 0%, #18a0fb 100%);
      border: 1px solid #eef6ff;
      color: #fff;
    }
  }
}
.marginTop {
  display: block;
  margin-top: 1rem;
}

.cookiesForm {
  filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
  border: 1px solid #18a0fb;
  background: #ffffff;
  border-radius: 5px;
  .formBody {
    padding: 1.6rem;
  }
}

.cookiesDisplay {
  filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
  border: 1px solid #18a0fb;
  background: #ffffff;
  border-radius: 5px;
  padding: 1.5rem;
  .section {
    &_header {
      position: relative;
      padding: 1rem 1.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-size: 1.125rem;
        font-weight: 500;
      }
      .disable {
        position: absolute;
        height: 100%;
        width: 100px;
        top: 0;
        right: 0;
        background-color: #ffffff8d;
      }
    }
    .description {
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding: 0rem 1.5rem;
    }
  }
}
.cookieActions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
  padding-right: 1rem;
  .edit_cookie {
    cursor: pointer;
  }
  .delete {
    cursor: pointer;
    border-left: 1px solid #d9dfe4;
    padding-left: 0.688rem;
    margin-left: 0.688rem;
  }
}

.headerTab_marginTop {
  margin-top: 1rem;
}
.createTermsRubric {
  margin: 0 20%;
}

@media screen and (max-width: 768px) {
  .right {
    padding-left: 0rem;
  }
  .fakeTop {
    display: none;
  }
  .hideOnIpad {
    display: none;
  }
  .showOnIpad {
    display: block;
    margin-bottom: 1.25rem;
  }
  .headerTab {
    margin-top: 1rem;
  }
}
