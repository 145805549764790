.container {
  display: flex;
}
.authorsContainer {
  width: 207px;
}

.authorTitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #3c4e64;
  margin-bottom: 10px;
}

.chainContainer {
  width: 207px;
  min-height: 143px;
  background-color: #f3faff;
  border: 1px solid #c7e8fe;
  border-radius: 5px;
  padding: 10px;
  margin: 0 auto 40px;
}

.rightContainer {
  width: 100%;
  margin-left: 1rem;
}

*:focus {
  outline: none;
}

.modal {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(41, 57, 77, 0.6);
  z-index: 1000;

  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.modalOverlayAfterOpen {
  opacity: 1;
}

.modalOverlayBeforeClose {
  opacity: 0;
}

.modalContent {
  position: absolute;
  top: 0;
  left: 0;
  right: -100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  overflow: none;
  outline: none;
  padding: 0;

  transition: right 300ms ease-in-out;
}

.modalContentAfterOpen {
  right: 0;
}

.modalContentBeforeClose {
  right: -100%;
}

.header {
  height: 90px;
  background-color: #f1f2f4;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2% 1.5rem 3%;

  h2 {
    line-height: 1.25;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0 0.5rem;
  }
  p {
    color: #6d7f92;
    font-size: 12px;
    line-height: 14px;
    margin: 0;
  }
}

.close {
  height: 17px;
  cursor: pointer;
}

.body {
  width: 100%;
  height: 100%;
  margin-left: auto;
  background-color: #ffffff;
  color: #29394d;
  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
.content {
  display: flex;
  padding: 24px 3%;
  h3 {
    font-size: 1rem;
    line-height: 19px;
    font-weight: 500;
    margin: 0 0 1rem;

    span {
      font-weight: 400;
    }
  }
}

.left {
  width: 35%;
  margin-right: 1.5rem;

  &_title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    padding: 0 1rem 0.5rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #3c4e64;
  }

  .control {
    padding: 1rem 1rem 1rem 0;
    display: flex;
    align-items: center;
    span {
      color: #18a0fb;
      font-size: 14px;
      font-weight: 500;
    }
    button {
      white-space: nowrap;
      padding: 0.675rem 2rem;
    }

    &__row {
      justify-content: space-between;
    }
  }
  .plus {
    border: 1px solid #18a0fb;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    background-color: #fff;
    width: 44px;
    height: 44px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 12px;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 15px;
      height: 2px;
      background-color: #18a0fb;
      border-radius: 2px;
    }
    &::after {
      transform: rotate(90deg);
    }
  }
}

.right {
  width: 65%;
}
.rightContent {
  overflow-y: auto;
  min-height: 200px;
  max-height: calc(100vh - 170px);
  padding: 1.5rem;
  border: 1px solid #18a0fb;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
}

.configRow {
  display: flex;
  width: 100%;
  margin-bottom: 1.5rem;
}

.configColumn {
  width: 50%;
  padding-right: 20px;

  input {
    // height: 1rem;
    box-shadow: none;
    margin: 0;
  }
}

.configLabel {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #3c4e64;
  display: block;
  margin-bottom: 10px;

  &_mar {
    margin-bottom: 20px;
  }
}

.configLabelFlex {
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
  }
}

.formRow {
  margin-bottom: 1.5rem;
}

.formInput {
  display: block;
  width: 100%;
  padding: 2px 10px;
  height: 38px !important;
  border: 1px solid #ced4db;
  border-radius: 5px;
  color: #29394d;
  background-color: #f8f9fa;
  border: 1px solid #b2bcc6;
}

.formTextarea {
  display: block;
  width: 100%;
  padding: 2px 10px;
  border: 1px solid #ced4db;
  border-radius: 5px;
  color: #29394d;
  background-color: #f8f9fa;
  border: 1px solid #b2bcc6;
}

.formControls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 4rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(178, 188, 198, 0.5);

  .okBtn {
    min-width: 170px;
    justify-content: center;
  }

  .actions {
    display: flex;

    span {
      cursor: pointer;
      background: #ffffff;
      border: 1px solid #b2bcc6;
      border-radius: 5px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;

      &:hover {
        background-color: rgba(109, 127, 146, 0.1);
      }
    }
  }
}

.addFaqContainer {
  background: #f8f9fa;
  border: 1px solid #c7e8fe;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 28px 13px;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
}
.addFaq {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6d7f92;
  cursor: pointer;

  &_icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    font-size: 22px;
    background: #fff;
    border: 0.5px solid #c7e8fe;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  }
}

@media screen and (max-width: 39.9375em) {
  .body {
    padding: 60px 20px 200px 20px;
  }

  .configRow {
    flex-direction: column;
    margin-bottom: 0;
  }
  .configColumn {
    width: 100%;
    margin-bottom: 2rem;
    padding-right: 0;
  }
}

@media screen and (min-width: 992px) {
  .body {
    width: 90%;
  }
}

.iconSelector {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10rem;
  border-radius: 5px;
}

.selectIconArea {
  background-color: #f1f2f4;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.selectIcon {
  font-weight: 500;
  border: 1px dashed #ccc;
  color: #6d7f92;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 6.5rem;
  text-align: center;
  font-size: 0.75rem;
  i {
    font-size: 3rem;
    color: #18a0fb;
    opacity: 0.7;
  }
  p {
    margin: 0;
    font-size: 0.825rem;
    margin-top: 0.5rem;
    font-weight: 600;
    color: #18a0fb;
    text-transform: capitalize;
  }
  &.icon-selected {
    border: none;
    span {
      display: none;
    }
    &:hover {
      p {
        display: none;
      }
      border: 2px dashed #ccc;
      span {
        display: block;
      }
    }
  }
}

.inputContainer {
  display: flex;
  align-items: center;

  .input {
    flex: 1;
  }

  .inputAdd {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    border-radius: 50%;
    cursor: pointer;
    color: #18a0fb;
    background: #fff;
    border: 1px solid #18a0fb;
  }

  &.active {
    .popOver {
      display: block;
    }

    .inputAdd {
      background-color: #18a0fb;
      color: #fff;
    }
  }
}

.popOver {
  display: none;
  position: absolute;
  top: 50%;
  left: -362px;
  width: 350px;
  min-height: 100px;
  max-height: 80vh;
  overflow: scroll;
  transform: translateY(-50%);
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #18a0fb;
  box-sizing: border-box;
  border-radius: 5px;
  z-index: 121;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    right: -8px;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 7px 0 7px 7px;
    border-color: transparent transparent transparent #18a0fb;
  }
}

.switches {
  li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    border-bottom: 1px solid #ced4db;
    font-size: 12px;
    line-height: 14px;
    color: #6d7f92;
    height: 44px;
    text-transform: capitalize;
    padding-left: 24px;
    padding-right: 6px;
  }
}

.switchRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px 0;
  border-bottom: 1px solid #ced4db;
}

@media screen and (max-width: 39.9375em) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .rightContainer {
    margin-left: 0;
  }
}

.suggetModalActions {
  padding: 2%;
  margin-top: 0rem;
}
.suggetModal {
  padding-left: 2%;
  padding-right: 2%;
}

// Ant modal styling
.modal_close {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
}

.modal_header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 1rem;
  background: #f3faff;
  border-bottom: 1px solid rgba(199, 232, 254, 0.5);
  border-radius: 0px 0px 5px 5px;
  font-size: 20px;
  line-height: 23px;
  color: #2495e1;

  &_sub {
    font-size: 18px;
    margin-left: 10px;
  }
}
.modal_icon {
  margin-right: 1rem;
}
.modal_body {
  width: 100%;
  height: 100%;
  max-height: 39rem;
  overflow-y: scroll;
  padding: 20px 42px 42px 42px;
  border-bottom: 1px solid #f1f2f4;
  font-size: 18px;
  line-height: 21px;
  color: #29394d;
}

.modal_controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(109, 127, 146, 0.1);
  border-radius: 2px;
  background-color: #ffffff;
  padding: 10px;
  width: 100%;
  z-index: 9999;
}

.switchFlex {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  .configLabel {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}

.tag {
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
}

.tagHover:hover {
  cursor: pointer;
  color: #fff;
  background-color: #6d7f92;
}
