.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #29394d;
  margin-bottom: 1.563rem;
  .sub_title {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #6d7f92;
    margin-top: 0.35rem;
    width: 97%;
    padding-bottom: 0.875rem;
    border-bottom: 1px solid #d8dde2;
  }
}
.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(41, 57, 77, 0.4);
  z-index: 999;
  width: 100%;
  height: 100%;

  &.hide {
    display: none;
  }
}

.sidebar {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 30%;
  right: 0;
  z-index: 1000;
  transform: translateX(100%);
  transition: 0.5s all ease-in-out;
  padding: 2rem;
  overflow-y: scroll;

  &.small {
    left: 40%;
  }

  &.display {
    transform: translateX(0);
    box-shadow: 0 0 1.2rem 0 rgba(44, 43, 63, 0.3);
  }
}

.close {
  text-align: right;
  cursor: pointer;
}
.cardCont {
  padding-right: 1.25rem;
}
.card {
  width: 11.25rem;
  height: 17.25rem;
  border: 1px solid #d8dde2;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  cursor: pointer;

  .blue {
    position: absolute;
    top: 4.216rem;

    width: 23.563rem;
    height: 23.125rem;
    background: linear-gradient(
      180deg,
      #f3faff 0%,
      rgba(243, 250, 255, 0) 100%
    );
    border-radius: 100%;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }
  .icon {
    width: 5.625rem;
    height: 5.625rem;
    border: 3px solid #ffffff;
    background: #f8f9fa;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.75rem;
  }
  .name {
    font-weight: 500;
    font-size: 1.125rem;
    text-transform: uppercase;
    margin-top: 1.125rem;
  }
  .description {
    width: 10.25rem;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.875rem;
    text-align: center;
    color: #6d7f92;
    margin-top: 1.125rem;
  }
  &:hover {
    .blue {
      top: -1rem;
    }
    .name {
      color: #18a0fb;
    }
    .icon svg {
      color: #18a0fb;
    }
  }
}

.back {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.75rem;
  border-top: 1px solid #d8dde2;
  .btn {
    display: flex;
    align-items: center;
    button {
      width: 37px;
      height: 36px;
      background: #f8f9fa;
      border: 1px solid #b2bcc6;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1.125rem;
    }
    span {
      font-weight: 500;
      font-size: 0.875rem;
      text-transform: uppercase;
      color: #18a0fb;
    }
  }
  .btns_group {
    display: flex;
    align-items: center;
  }
  .upload_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    width: 11.25rem;
    height: 2.25rem;
    border: 1px solid #18a0fb;
    border-radius: 5px;
    padding: 0 1.25rem;
    font-weight: 500;
    font-size: 0.875rem;
    color: #18a0fb;
    margin-left: 0.5rem;
  }
  .embed_btn {
    width: fit-content;
  }
}

@media screen and (max-width: 39.9375em) {
  .sidebar {
    left: 0%;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .sidebar {
    left: 0%;
  }
}
