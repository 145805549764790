.page {
  width: 100%;
  max-width: 1278px;
  margin: 0 auto;
  padding: 1rem;
}

.pageTitle {
  font-size: 32px;
  line-height: 34px;
  margin: 1rem 0;
}

.content {
  display: flex;
  padding: 24px 0;
  h3 {
    font-size: 1rem;
    line-height: 19px;
    font-weight: 500;
    margin: 0 0 1rem;

    span {
      font-weight: 400;
    }
  }
}

.left {
  width: 35%;
  margin-right: 1.5rem;

  &_title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    padding: 0 1rem 0.5rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #3c4e64;
  }

  .control {
    padding: 1rem 1rem 1rem 0;
    display: flex;
    align-items: center;
    span {
      color: #18a0fb;
      font-size: 14px;
      font-weight: 500;
    }
    button {
      white-space: nowrap;
      padding: 0.675rem 2rem;
    }

    &__row {
      justify-content: space-between;
    }
  }
  .plus {
    border: 1px solid #18a0fb;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    background-color: #fff;
    width: 44px;
    height: 44px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 12px;
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 15px;
      height: 2px;
      background-color: #18a0fb;
      border-radius: 2px;
    }
    &::after {
      transform: rotate(90deg);
    }
  }
}

.right {
  width: 65%;
}
.rightContent {
  // overflow-y: auto;
  min-height: 200px;
  // max-height: calc(100vh - 170px);
  padding: 1.5rem;
  background-color: #fff;
  border: 1px solid #18a0fb;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
}

.addFaqContainer {
  background: #f8f9fa;
  border: 1px solid #c7e8fe;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 28px 13px;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
}
.addFaq {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6d7f92;
  cursor: pointer;

  &_icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    font-size: 22px;
    background: #fff;
    border: 0.5px solid #c7e8fe;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  }
}
