.list {
  list-style: none;
}
.container {
  li {
    background-color: #fff;
    border-top: 1px solid #dce1e5;
    &:first-child {
      border-top: none;
    }
  }
  &.active {
    max-height: 100vh;
  }
}
.arrow {
  cursor: pointer;
  color: #6d7f92;
  padding: 0 1rem;
  border-left: 1px solid #d9dfe4;
  svg {
    transition: all 0.3s linear;
    //transform: rotate(-90deg);
  }
}

.fetchItem {
  margin-bottom: 10px;
}

.parentRubric,
.parentArrow {
  position: relative;
}
ul {
  padding: 0;
}
.itemsList {
  // max-height: calc(100vh - 220px);
  // overflow-y: auto;
}

.item {
  margin-bottom: 0.75rem;
  z-index: 99; // for sort
  background: #f8f9fa;
  border: 1px solid #dce1e5;
  border-radius: 5px;
  list-style: none;
  padding: 0.5rem 0;
  min-height: 44px;

  &.activeList {
    background-color: #fff;
    border: 1px solid #18a0fb;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    & > .main .arrow svg {
      transform: rotate(-180deg);
    }
    .item_content_parent {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
    }
    & > .item_content {
      height: auto;
      & > ul {
        height: auto;
      }
      h5 {
        height: auto;
      }
      .addFaq {
        height: 37px;
        border: 0.5px solid #c7e8fe;
      }
    }
  }
  &_content {
    flex: 1;
    //display: none;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    height: 0;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    h5 {
      margin: 0;
      margin-bottom: 0.25rem;
      color: #6d7f92;
      font-weight: 500;
      font-size: 0.75rem;
      height: 0;
      overflow: hidden;
    }
    ul {
      height: 0;
      overflow: hidden;
    }
  }

  &_title {
    padding: 0 1rem 1rem;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
  }

  &_subtitle {
    padding: 0rem 1rem 0 2rem;
    display: flex;
    align-items: center;
    flex: 1;
    font-weight: 500;
  }

  &_editsubtitle {
    padding: 0rem 1rem 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    font-weight: 500;
  }
  h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.25;
    margin: 0 0 0 1rem;
  }
}
.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title,
.actions {
  display: flex;
  align-items: center;
}
.actions {
  margin-left: 1rem;
}
.itemList {
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.subRubric {
  z-index: 99;
  //color: #fff;
  list-style: none;
  border: none;
  h4 {
    font-size: 13px;
    font-weight: 600;
  }
  &.activeList {
    border: none;
    box-shadow: none;
    background-color: #fff;
    border-top: 1px solid #dce1e5;
  }
  .item_content {
    padding-right: 0;
  }
}
.questionContainer {
  display: flex;
  align-items: center;
  z-index: 99; // for sort
  background-color: #fff;
  cursor: pointer;
  padding: 0.5rem 1rem 0.5rem 0;
  border-radius: 5px;

  &:hover {
    background-color: #f1f2f4;
  }

  &.active {
    background-color: #18a0fb;
    h6 {
      color: #fff;
    }
    .dragHandler {
      color: #fff;
    }
  }
  h6 {
    margin: 0;
    margin-left: 1rem;
    font-weight: 500;
    font-size: 12px;
  }
}
.addFaq {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  overflow: hidden;
  margin-top: 1rem;
  cursor: pointer;
  color: #18a0fb;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  background: #fff;
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);

  &.active,
  &:hover {
    background: #18a0fb;
    color: #fff;
  }

  span {
    margin-left: 0.5rem;
  }
}

.edit {
  cursor: pointer;
  padding: 0 0.75rem;
}

.questionTitle {
  //font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 1.25;
  // text-transform: uppercase;
  // color: #29394d;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-bottom: 1rem;
  // border-bottom: 1px solid rgba(178, 188, 198, 0.5);

  &_left {
    display: flex;

    span {
      margin-right: 10px;
    }
  }
}

.addRubricFaq {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // color: #18a0fb;
  // cursor: pointer;
  // font-size: 16px;
  // line-height: 18px;
  // min-width: 200px;
  margin-left: 1rem;

  &_icon {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border: 1px solid #fff;
    border-radius: 3px;
    position: relative;
    &:hover {
      border: 1px solid #18a0fb;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    }
    &:first-child {
      margin-right: 1rem;
      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 1px;
        background-color: #d9dfe4;
        left: 1.675rem;
      }
    }
  }
}

.helpFul {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(178, 188, 198, 0.5);
  color: #6d7f92;
  font-size: 14px;
  font-weight: 600;

  &_btn {
    padding: 0.2rem 1rem;
    border-radius: 5px;
    margin-left: 1rem;
    cursor: pointer;
    border: 1px solid #c7e8fe;
    color: #18a0fb;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    font-weight: 500;
    &:hover {
      background-color: #18a0fb;
      color: #fff;
    }
  }
}

.dragHandler {
  margin-left: 10px;
  cursor: move;
  height: 24px;
}
