.publishRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid #ced4db;
  box-sizing: border-box;
  border-radius: 3px;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  margin-bottom: 5px;

  &.active {
    border-color: #2495e1;
  }

  input {
    border: none;
    text-align: right;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.09em;
    color: #2495e1;
  }
}

.publishLabel {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #6d7f92;
}
