*,
h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}

.chainsPage {
  background-color: #fafbfb;
  color: #29394d;
}
.pageContent {
  padding: 2rem 5%;
}
.backNav {
  height: 60px;
  padding-left: 5.7%;
  background: #f3faff;
  border-bottom: 1px solid #c7e8fe;
  display: flex;
  align-items: center;

  button {
    height: 2.25rem;
    padding: 0px 1.188rem;
    background: #f8f9fa;
    border: 1px solid #b2bcc6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    margin-right: 1.188rem;
    svg {
      margin-right: 0.688rem;
    }
  }
  span {
    color: #18a0fb;
    font-size: 1rem;
    font-weight: 500;
  }
}

.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.125rem;
  .titles {
    h1 {
      font-weight: 500;
      font-size: 2rem;
      line-height: 38px;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }
    h2 {
      font-weight: 400;
      font-size: 1rem;
      line-height: 19px;
      color: #6d7f92;
    }
  }
}

.chain {
  margin: 0 -8px;
  margin-bottom: 3.75rem;
}
.bannerDiv {
  padding: 0 8px;
}
.chainContent {
  padding: 0 8px;
}

.leftBanner {
  background-color: #f1f2f4;
  padding: 1.438rem 1.25rem 1.688rem 1.25rem;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 5px;
  .chainSpan {
    display: block;
    color: #18a0fb;
    font-size: 1.25rem;
    line-height: 23px;
    margin-bottom: 0.5rem;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 28px;
    margin-bottom: 0.688rem;
  }
  .description {
    text-transform: none;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 16px;
    color: #6d7f92;
    margin-bottom: 1.75rem;
    & > label {
      display: block;
      width: fit-content;
      text-decoration-line: underline;
      cursor: pointer;
      margin-top: 0.625rem;
    }
  }
}
.chainLogo {
  height: 154px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #faf9fa;
}
.seeChain {
  height: 44px;
  width: 10.75rem;
  background: #ffffff;
  border: 1px solid #d8dde2;
  border-radius: 5px;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  filter: drop-shadow(0px 4px 10px rgba(41, 57, 77, 0.1));
  margin-top: 2.313rem;
}
.contentTitle {
  margin-bottom: 1.188rem;
  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }
  .lng {
    display: flex;
    justify-content: flex-end;
  }
}
.slider {
  width: 100%;
  overflow: hidden;
  position: relative;
  left: 0%;
  top: 0%;
  margin-top: -6%;
  padding-top: 5.5%;

  & > button {
    display: none;
  }
}
.sliderDots {
  width: 100%;
  display: flex !important;
  justify-content: flex-end;
  height: fit-content;
  position: absolute;
  top: 0%;
  right: 12%;
  z-index: 100;
  li {
    height: 10px;
    width: 10px;
    button {
      padding: 4px;
      height: 8px;
      width: 8px;
    }
  }
}
.sliderButtons {
  display: flex;
  align-items: center;
  z-index: 9999;
  justify-content: flex-end;
  button {
    width: 44px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #d8dde2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 0.5rem;
  }
}
.rubricsList {
  padding: 0 8px;
}

.rubrics {
  margin: 0 -7px;
}

.rubricCardCont {
  padding: 0 6px;
  padding-bottom: 12px;
}

.users {
  display: block;
  margin-top: 1.688rem !important;
  padding-top: 1.563rem !important;
  border-top: 1px solid #d8dde2;
  li {
    padding: 0.75rem 0.875rem;
    height: 88px;
    background: #ffffff;
    border: 1px solid #f1f2f4;
    border-radius: 5px;
    margin-top: 5%;
    display: flex;
    cursor: pointer;
    &:first-child {
      margin-top: 0px;
    }
    .userAvatar {
      padding-right: 0.875rem;
      .img {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    .userInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      padding: 0.5rem 0rem;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      p {
        width: max-content;
        font-weight: 500;
        font-size: 1rem;
        line-height: 19px;
      }
      span {
        display: block;
        width: max-content;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 14px;
        color: #6d7f92;
      }
    }
  }
}

.showMore {
  border-top: 1px solid #b2bcc6;
  position: relative;
  margin: 70px 0;
  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // background: $body-bg;
    padding: 0 43px;
  }
  button {
    cursor: pointer;
    padding: 12px 22px;
    background: #ffffff;
    border: none;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 50px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #29394d;
    i {
      margin-left: 12px;
    }
  }
}

@media screen and (max-width: 768px) {
  .users li .userInfo {
    padding-left: 0.5rem;
  }
  .contentTitle .lng {
    justify-content: flex-start;
  }
  .sliderButtons {
    margin-top: 0.5rem;
    justify-content: flex-start;
    button {
      margin-left: 0rem;
      margin-right: 0.5rem;
    }
  }
}
@media screen and (max-width: 375px) {
  .contentTitle {
    margin-top: 2rem;
  }
  .users li .userInfo {
    padding-left: 0rem;
  }
}
