.breadcrumb {
  display: flex;
  li {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    color: #6d7f92;
    span {
      margin: 0% 4px;
      color: #6d7f92;
      cursor: pointer;
    }
    a {
      margin: 0% 4px;
      color: #6d7f92;
      cursor: pointer;
    }
    &:first-child a {
      margin-left: 0px;
    }
  }
}

@media screen and (max-width: 375px) {
  .breadcrumb {
    li:not(:last-child) {
      display: none;
    }
  }
}
