.article {
  width: 100%;
  border: 1px solid #f8f9fa;
  border-radius: 5px;
  margin-bottom: 1.25rem;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  position: relative;
  padding: 0.75rem;
  display: flex;
  background-color: #fff;
  &.selected {
    background-color: #f3faff;
    .icon {
      display: flex;
    }
    &:hover {
      .icon {
        .other {
          display: none;
        }
        .check {
          display: initial;
        }
      }
    }
  }
}

.hover_effect {
  &:hover {
    background-color: #f3faff;
    .icon {
      display: flex;
    }
  }
}
.img {
  height: 5.875rem;
  width: 11.25rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
}
.content {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  width: calc(100% - (8.438rem + 0.75rem));
}

.price_time {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}
.price,
.time {
  background: #f1f2f4;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.375rem;
  margin-right: 0.5rem;
  font-weight: 500;
  font-size: 0.75rem;
  padding: 0 0.625rem;
}

.title {
  font-weight: 500;
  font-size: 0.875rem;
}

.speaker {
  font-size: 0.75rem;
  color: #6d7f92;
  margin-bottom: 0.5rem;
}
.replay {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.75rem;
  svg {
    margin-right: 0.5rem;
  }
}
.replay_span {
  font-size: 0.75rem;
  margin-left: 1.4rem;
}
.certificate {
  display: flex;
  align-items: center;

  font-size: 0.75rem;
  svg {
    margin-right: 0.5rem;
  }
}

.read_more {
  margin-top: auto;
  margin-left: auto;
  font-weight: 500;
  font-size: 0.625rem;
  color: #6d7f92;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  svg {
    margin-left: 0.5rem;
  }
  &:hover {
    color: #6d7f92;
  }
}

.icon {
  position: absolute;
  top: 5rem;
  right: 2.438rem;
  height: 2.5rem;
  width: 2.5rem;
  background: #ffffff;
  border: 1px solid #18a0fb;
  border-radius: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: #18a0fb;
  font-weight: 500;
  font-size: 18px;
  .check {
    display: none;
  }
  display: none;
}

.eventContainer {
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  position: relative;
  border-radius: 5px;
  padding-bottom: 0.75rem;
  // margin-bottom: 0.625rem;
}

.dragHandler {
  height: 100%;
  width: 3.125rem;
  font-size: 2rem;
  color: #b6bfc8;
  cursor: move;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.eventContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 1.25rem;
  .img {
    height: 10rem;
    width: 18.75rem;
  }
  .content {
    .top {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;
    }
    .title {
      font-weight: 500;
      font-size: 1rem;
    }
    .speaker {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      .speaker_img {
        height: 2.5rem;
        width: 2.5rem;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 100%;
      }
      .info {
        margin-left: 0.313rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .name {
          font-weight: 500;
          font-size: 0.875rem;
          width: max-content;
          height: 15.56px;
          color: #29394d;
        }
        .headline {
          color: #6d7f92;
          font-size: 0.625rem;
        }
      }
    }
    .infos {
      font-size: 0.875rem;
      margin-bottom: 1.5rem;
      i {
        margin-right: 0.5rem;
      }
    }
    .event_price {
      display: flex;
      align-items: center;
      span {
        font-weight: 500;
        font-size: 1.5rem;
        margin-right: 2rem;
      }
      button {
        width: 10.375rem;
        height: 2.25rem;
        background-color: #f1f2f4;
        color: #6d7f92;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 0.875rem;
        border-radius: 5px;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
  }
}

.threeDots {
  margin-right: 0.5rem;
  transform: rotate(90deg);
  cursor: pointer;
  font-size: 14px;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f1f2f4;

  &.active {
    background-color: #18a0fb;
    color: #fff;
    font-size: 12px;
  }
}

.toolTipContainer {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  z-index: 1;
}
.sharingDate {
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
}
