@import "../../../styles/base/variables";
.rtheme {
  height: 100%;
  display: flex;
  flex-direction: column;
  .tab-list {
    border: none;
    margin: 0 -4px;
  }
  .tab-list-active {
    .tab-btn {
      background-color: $new-gray;

      border-radius: 30px;
      font-weight: 500;
      color: #ffffff;
      text-shadow: 0px 4px 4px rgba(41, 57, 77, 0.5);
    }
  }
  .tab-list-item {
    padding: 0 4px 22px;
    > div {
      height: auto;
    }
    span {
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      color: $new-gray;
      font-size: 0.625rem;
      line-height: 0.625rem;
      text-align: center;
      letter-spacing: 0.02em;
    }
    &:after {
      display: none;
    }
  }
  .tab-content {
    overflow-y: scroll;
    background-color: #fff;
    flex: 1;
    padding: 1rem;
    i {
      font-size: 1rem;
      margin-right: 6px;
    }
  }
}
.tab-list {
  color: #8d8d8d;
  border-bottom: 3px solid #b2bcc6;
  margin: 0;
  padding: 0;
}
.tab-content {
  background: #f8f9fa;
  border: 1px solid #c7e8fe;
  border-top: none;
  box-sizing: border-box;
  border-radius: 0px 0px 4px 4px;
  padding: 28px 13px;
}
.tab-hidden {
  display: none !important;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  cursor: pointer;
  margin-bottom: -4px;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #6d7f92;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 35px;
  }
  img {
    margin-right: 7px;
    height: 11px;
  }
}

.tab-list-active {
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 5px;
    background-color: #18a0fb;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 10px;
  }
}

.wtheme {
  .tab-content {
    background: #fff;
    border: 0;
    padding: 1.5rem 0;
  }
}
