.searchPage {
  background-color: #f1f2f4;
  color: #29394d;
}
.leftBanner {
  min-height: 50rem;
  height: 100%;
  background: #29394d;
  padding: 1.625rem 1.438rem 0rem 1.438rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.isUa {
  padding-left: 4rem;
  .closeBtn {
    margin-right: unset;
    margin-left: auto;
  }
}
.closeBtn {
  margin-bottom: 1.875rem;
  background-color: inherit;
  border: none;
  outline: none;
  margin-right: auto;
}

.bannerHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.3rem;
    text-align: center;
    color: #c7e8fe;
    margin-top: 1.75rem;
  }
  span {
    font-weight: 400;
    font-size: 1rem;
    line-height: 140%;
    text-align: center;
    color: #f1f2f4;
    margin-top: 0.5rem;
  }
}
.bannerContent {
  padding: 0rem 1.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pageContent {
  padding: 1.875rem 5.19%;
}
.pageHeader {
  margin-bottom: 1.688rem;
  h4 {
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: #18a0fb;
  }
}
.cardsList {
  margin: 0 -10px;
}

.cardCont {
  padding: 0 10px;
  padding-bottom: 20px;
}

.card {
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  padding: 0.938rem 1.188rem 0.938rem 1.063rem;
  font-weight: 500;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  span {
    font-size: 0.875rem;
    color: #18a0fb;
    margin-bottom: 0.375rem;
  }
  h5 {
    font-size: 1.5rem;
    line-height: 28px;
    text-transform: uppercase;
    color: #29394d;
    word-break: break-word;
  }
  &:hover {
    background: #f3faff;
    border: 1px solid #c7e8fe;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    .icons svg {
      display: initial;
    }
    .info svg {
      display: initial;
    }
  }
}
.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  i {
    font-size: 30px;
  }
  svg {
    display: none;
    margin-top: auto;
  }
}
.goBack {
  display: flex;
  align-items: center;
  button {
    width: 44px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #d8dde2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 1rem;
  }
}
.info {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  .chainImg {
    width: 58px;
    height: 34px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: #faf9fa;
    margin-right: 0.5rem;
  }

  svg {
    margin-left: auto;
    display: none;
  }
}
.users {
  margin-left: 10px;
  display: flex;
  .avatar {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 2px solid #f1f2f4;
    background-color: #fff;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: -10px;
  }
  .extraUsers {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background: #d8dde2;
    border: 2px solid #d8dde2;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #6d7f92;
    margin-left: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.cardBody {
  display: flex;
  flex-direction: column;
}
.cardFooter {
  margin-top: auto;
}
.bannerFooter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3.063rem;
  .chainImg {
    width: 268px;
    height: 154px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: #faf9fa;
    margin-top: 2.125rem;
  }
}
.numberQuestions {
  text-align: center;
  border-top: 1px solid #fff;
  color: #fff;
  width: 90%;
  span {
    position: relative;
    background-color: #29394d;
    padding: 0 22px;
    top: -13px;
  }
}
.no_data {
  font-size: 1rem;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .leftBanner {
    display: none;
  }
  .pageContent {
    min-height: 83vh;
  }
}
@media screen and (max-width: 375px) {
  .pageContent {
    min-height: 85vh;
  }
}
