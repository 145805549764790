.attachmentsDropzone {
  margin-top: 15px;
  height: 116px;
  border: 1px dashed #ced4db;
  background-color: #f1f2f4;
  border-radius: 3px;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.dropzoneImg {
  margin: 0 auto;
  font-size: 1rem;
}

.dropzoneText {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
}

.attachmentItem {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
}

.trashIcon {
  cursor: pointer;
  margin-left: 10px;
  color: #fe3745;
  font-size: 14px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.rotating {
  animation: rotation 1s infinite linear;
}
