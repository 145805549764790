.card {
  height: 422px;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 5px;
  padding: 2.188rem 1.25rem 1.563rem 1.25rem;
  display: flex;
  flex-direction: column;
  position: relative;

  ul {
    max-height: 280px;
    overflow-y: auto;
    margin-bottom: 2rem;
    &::-webkit-scrollbar {
      display: none;
    }
    li {
      padding: 8px 0;
      border-bottom: 1px solid #d8dde2;
      display: flex;
      text-transform: capitalize;
      line-height: 1.25;
      cursor: pointer;
      &:last-child {
        border: none;
      }
      &:hover {
        p,
        svg {
          color: #18a0fb;
        }
      }
    }
    .active {
      p,
      svg {
        color: #18a0fb;
      }
    }
  }
  .blueArrow {
    margin-left: auto;
    margin-right: 5%;
    width: fit-content;
    opacity: 0%;
    cursor: pointer;
  }
  &:hover {
    .blueArrow {
      opacity: 100%;
    }
  }
}

.title {
  text-align: center;
  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 28px;
    height: 3.5rem;
    overflow-y: auto;
    cursor: pointer;
    margin-bottom: 2.875rem;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.nbPages {
  text-align: center;
  border-top: 1px solid #d8dde2;
  span {
    position: relative;
    background-color: #fff;
    padding: 0 26px;
    top: -13px;
  }
}
.authors {
  margin-top: auto;
  display: flex;
  align-items: center;
  .image {
    width: 58px;
    height: 34px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: #faf9fa;
    margin-right: 0.5rem;
  }
  .users {
    margin-left: 10px;
    display: flex;
    .avatar {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      border: 2px solid #f1f2f4;
      background-color: #fff;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      margin-left: -10px;
    }
    .extraUsers {
      height: 34px;
      width: 34px;
      border-radius: 50%;
      background: #d8dde2;
      border: 2px solid #d8dde2;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #6d7f92;
      margin-left: -10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.status_eye {
  color: #18a0fb;
  position: relative;
  cursor: pointer;

  &_absolute {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  i {
    font-size: 18px;
  }

  &::before {
    display: none;
    content: " ";
    border-left: 2px solid #18a0fb;
    position: absolute;
    top: -5px;
    bottom: 0;
    left: 40%;
    transform: rotate(45deg);
  }

  &.hidden {
    opacity: 0.7;

    &::before {
      display: block;
    }
  }
}
