.languages {
  margin: 0;
  display: flex;
  align-items: center;
  height: 44px;

  li {
    height: 32px;
    width: 32px;
    border-radius: 100%;
    background: #f1f2f4;
    border: 1px solid #d8dde2;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #6d7f92;
    margin-left: 6px;
    cursor: pointer;
    &:first-child {
      margin-left: 0px;
    }
  }
  .active {
    background-color: #18a0fb;
    color: #f1f2f4;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
