.container {
  width: 280px;
  background: #ffffff;
  border: 1px solid #18a0fb;
  box-sizing: border-box;
  border-radius: 5px;
  &:before {
    position: absolute;
    content: "";
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 7px 0 7px 10px;
    border-color: transparent transparent transparent #18a0fb;
  }
  z-index: 10;
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
  }
  .title {
    font-size: 12px;
    line-height: 13px;
    text-transform: uppercase;
    color: #2495e1;
  }
  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    color: #29394d;
  }

  > div {
    flex: 1;
    margin: 0 14px;
    font-weight: 500;
    font-size: 14px;
    line-height: 13px;
    color: #29394d;
  }

  ul {
    li {
      margin-top: 1rem;
      border-bottom: 1px solid #f1f2f4;
      margin-bottom: 0.5rem;
      &:last-child {
        border: none;
      }
    }
  }
  button {
    margin-top: 1rem;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
