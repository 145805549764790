.article {
  width: 100%;
  border: 1px solid #f8f9fa;
  border-radius: 5px;
  margin-bottom: 1.25rem;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  position: relative;
  padding: 0.75rem;
  display: flex;
  background-color: #fff;
  &.selected {
    background-color: #f3faff;
    .icon {
      display: flex;
    }
    &:hover {
      .icon {
        .other {
          display: none;
        }
        .check {
          display: initial;
        }
      }
    }
  }
}
.hover_effect {
  &:hover {
    background-color: #f3faff;
    .icon {
      display: flex;
    }
  }
}
.img {
  height: 5.875rem;
  width: 8.438rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
}
.content {
  margin-left: 0.625rem;
  display: flex;
  flex-direction: column;
  width: calc(100% - (8.438rem + 0.75rem));
}
.authors {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 70%;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.author {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
  .author_img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 1.625rem;
    width: 1.625rem;
    border-radius: 100%;
  }
  .info {
    margin-left: 0.313rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .name {
      font-size: 0.625rem;
      color: #3c4e64;
      width: max-content;
      height: 12px;
    }
  }
}
.date {
  font-size: 0.5rem;
  color: #6d7f92;
  width: max-content;
}
.title {
  font-weight: 500;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}
.read_more {
  margin-top: auto;
  margin-left: auto;
  font-weight: 500;
  font-size: 0.625rem;
  color: #6d7f92;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  svg {
    margin-left: 0.5rem;
  }
  &:hover {
    color: #6d7f92;
  }
}
.category {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 2px;
  padding: 0 0.5rem;
  color: #fff;
  text-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
  font-size: 0.625rem;
}

.icon {
  position: absolute;
  top: 2.313rem;
  right: 2.438rem;
  height: 2.5rem;
  width: 2.5rem;
  background: #ffffff;
  border: 1px solid #18a0fb;
  border-radius: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: #18a0fb;
  font-weight: 500;
  font-size: 18px;
  .check {
    display: none;
  }
  display: none;
}
.edit {
  position: absolute;
  top: 0.75rem;
  right: 3rem;
  display: flex;
  align-items: center;
  .status {
    font-size: 12px;
    line-height: 14px;
    background-color: #b2bcc6;
    color: #fff;
    padding: 4px;
    border-radius: 4px;
    margin: 0 10px;

    &.published {
      background-color: #06d9b1;
    }
  }
  .item_edit {
    font-size: 12px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b2bcc6;
    border-radius: 50%;
    cursor: pointer;
    color: black;
  }
}

.articleContainer {
  height: 17.125rem;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  // margin-bottom: 0.625rem;
}
.dragHandler {
  height: 100%;
  width: 3.125rem;
  font-size: 2rem;
  color: #b6bfc8;
  cursor: move;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.articleContent {
  color: #fff;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 5px;
  padding: 1rem 1.188rem;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      rgba(9, 22, 38, 0.6),
      rgba(9, 22, 38, 0.6)
    );
    border-radius: 5px;
  }
  .top {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    .category {
      position: relative;
      border-radius: 2px;
      padding: 0 0.5rem;
      color: #fff;
      text-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
      font-size: 0.625rem;
    }
    .chain {
      width: 3.625rem;
      height: 2.205rem;
      background-color: #fff;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 5px;
    }
  }
  .meta {
    color: #f1f2f4;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.75rem;
    .community {
      padding-left: 0.813rem;
      margin-left: 0.875rem;
      border-left: 3px solid;
    }
  }
  .title {
    position: relative;
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 0.938rem;
  }
  .authors {
    margin-bottom: 0.75rem;
    position: relative;
    .author {
      .author_img {
        height: 2.188rem;
        width: 2.188rem;
      }
      .info {
        .name {
          color: #fff;
          font-size: 0.75rem;
          height: 15.56px;
        }
        .headline {
          font-size: 0.625rem;
          color: #f1f2f4;
        }
      }
    }
  }
  .publishedAtReadTime {
    position: relative;
    font-weight: 300;
    font-size: 10px;
    color: #f1f2f4;
    display: flex;
    align-items: center;
    .readTime {
      display: flex;
      align-items: center;
      .dot {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #f1f2f4;
        margin: 0 0.5rem;
      }
      span {
        width: 80px;
      }
    }
  }
  .readArticle {
    margin-top: 0.5rem;
    position: relative;
    width: 10.375rem;
    height: 2.25rem;
    background-color: #f1f2f4;
    color: #6d7f92;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 0.875rem;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

.flex {
  position: relative;
  display: flex;
  align-items: center;
}

.threeDots {
  transform: rotate(90deg);
  cursor: pointer;
  font-size: 14px;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.active {
    margin-left: 0.5rem;
    background-color: #18a0fb;
    color: #fff;
    font-size: 12px;
  }
}

.toolTipContainer {
  position: absolute;
  top: 50%;
  right: 45px;
  transform: translateY(-50%);
  z-index: 1;
}

.sharingDate {
  display: block;
  position: relative;
  font-size: 0.75rem;
  color: #f1f2f4;
  margin-bottom: 0.5rem;
}
